@import './styles/antd.less';
@import './styles/antd-global.less';
@import './styles/font.less';
html {
  text-size-adjust: none !important;
  font-size: 16px;

  padding: 0;
  margin: 0;
  height: auto;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: none !important;
}

/* * {
  border: 1px solid red;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@primary-color: #31D7A0;@link-color: #0fcd8c;@logo-color: #39E1A4;