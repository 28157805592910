// cover default antd css
.ant-steps-item-content {
  margin-top: -120px !important;
  width: auto !important;
  text-align: left !important;
}

.ant-collapse-item + .ant-collapse-item {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.ant-collapse-content-box {
  padding-top: 0px !important;
}

.ant-modal-content {
  border-radius: 8px !important;
}

.ant-menu-inline {
  border-right: none !important;
}
