/*  */

@font-face {
  font-family: 'Barlow';
  src: url('../../assets/fonts/Barlow-Regular.ttf');
}

@font-face {
  font-family: 'Barlow';
  src: url('../../assets/fonts/Barlow-Regular.ttf');
}

@font-face {
  font-family: 'Barlow Bold';
  src: url('../../assets/fonts/Barlow-Bold.ttf');
}

@font-face {
  font-family: 'Barlow Light';
  src: url('../../assets/fonts/Barlow Light.woff.ttf');
}

@font-face {
  font-family: 'Barlow Medium';
  src: url('../../assets/fonts/Barlow Medium.woff.ttf');
}

@font-face {
  font-family: 'Barlow Black';
  src: url('../../assets/fonts/Barlow-Black.woff2.ttf');
}

@font-face {
  font-family: 'Barlow Thin';
  src: url('../../assets/fonts/Barlow Thin.woff.ttf');
}
